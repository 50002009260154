<template>
  <radix-dropdown-menu-root v-model:open="open" :modal="isMobileView">
    <radix-dropdown-menu-trigger
      class="!outline-0"
      v-bind="$attrs"
      v-on="dropdownMenuElementListeners"
    >
      <slot v-bind="{ open: open }">
        <app-icon-button
          icon="ph:dots-three-vertical-bold"
          :icon-size="iconSize"
          label="Menu"
          tag="span"
        />
      </slot>
    </radix-dropdown-menu-trigger>

    <radix-dropdown-menu-portal to="body">
      <app-fade-transition>
        <div
          v-show="isMobileView && open"
          class="fixed z-[100] inset-0 bg-black/30"
        />
      </app-fade-transition>

      <transition
        enter-active-class="transition-[top,bottom,opacity]"
        :enter-from-class="`opacity-0 ${isMobileView ? '!-bottom-2 !top-auto' : 'md:!top-1'}`"
        leave-active-class="transition-[top,bottom,opacity]"
        :leave-to-class="`opacity-0 ${isMobileView ? '!-bottom-2 !top-auto' : 'md:!top-1'}`"
      >
        <radix-dropdown-menu-content
          :align="align"
          class="relative text-black shadow-lg text-sm min-w-[--minWidth] bg-white rounded-t-lg md:rounded"
          hide-when-detached
          :side="side"
          :side-offset="SIDE_OFFSET_IN_PX"
          :style="{ '--minWidth': `${menuMinWidth}px` }"
          v-on="dropdownMenuElementListeners"
        >
          <div
            class="absolute w-full left-0 h-[--offset] top-0 -translate-y-full"
            :style="{ '--offset': `${SIDE_OFFSET_IN_PX}px` }"
          />
          <template v-if="$slots.prepend">
            <div class="p-4">
              <slot name="prepend" />
            </div>
            <app-divider />
          </template>

          <div class="p-2" :class="{ 'px-4': isMobileView }">
            <template v-for="(item, index) in items">
              <radix-dropdown-menu-separator
                v-if="item.divider"
                :key="`divider_${index}`"
                class="my-2 -ml-2 -mr-2 border-t"
              />

              <radix-dropdown-menu-group
                v-else-if="item.group"
                :key="`group_${item.title}`"
              >
                <app-expandable :default-expanded="item.selected">
                  <template #trigger="{ open }">
                    <radix-dropdown-menu-label
                      class="text-lg md:text-sm font-semibold p-2 flex items-center justify-between"
                    >
                      {{ item.title }}
                      <app-icon
                        class="transition"
                        :class="{ 'rotate-180': open }"
                        icon="ph:caret-down"
                        :size="18"
                      />
                    </radix-dropdown-menu-label>
                  </template>

                  <template v-for="child in item.items">
                    <app-dropdown-menu-item
                      v-if="!child.divider && !child.group"
                      :key="child.label"
                      class="ml-4 !font-normal"
                      :class="{ 'text-lg py-3': isMobileView }"
                      :item="child"
                    />
                  </template>
                </app-expandable>
              </radix-dropdown-menu-group>

              <app-dropdown-menu-item
                v-else
                :key="item.label"
                :class="[{ 'text-lg py-3': isMobileView }, itemClass]"
                :item="item"
              />
            </template>
          </div>

          <template v-if="$slots.append">
            <app-divider />
            <div class="p-4">
              <slot name="append" />
            </div>
          </template>
        </radix-dropdown-menu-content>
      </transition>
    </radix-dropdown-menu-portal>
  </radix-dropdown-menu-root>
</template>

<script lang="ts" setup>
import type {
  DropdownMenuPositionAlign,
  DropdownMenuPositionSide,
  DropdownPosition,
} from "../composables/dropdown-position.hook";
import type { DropdownMenuItemDefinition } from "../dropdown.model";

const SIDE_OFFSET_IN_PX = 10;

defineOptions({ inheritAttrs: false });

const properties = withDefaults(
  defineProps<{
    items?: DropdownMenuItemDefinition[];
    position?: DropdownPosition;
    iconSize?: number;
    menuMinWidth?: number;
    trigger?: "click" | "hover";
    itemClass?: string;
  }>(),
  {
    items: () => [],
    position: "bottom-start",
    iconSize: 20,
    menuMinWidth: 280,
    trigger: "click",
    itemClass: "",
  },
);

defineSlots<{
  default: (props: { open: boolean }) => void;
  prepend: () => void;
  append: () => void;
  [key: `append_${string}`]: () => void;
  [key: `prepend_${string}`]: () => void;
}>();

const { width } = useWindowSize();
const isMobileView = computed(() => width.value < 768);

const side = computed(
  () => properties.position.split("-")[0] as DropdownMenuPositionSide,
);
const align = computed(
  () => properties.position.split("-")[1] as DropdownMenuPositionAlign,
);

const [open, toggle] = useToggle();

const closeTimeout = useTimeout(150, {
  controls: true,
  callback: () => {
    toggle(false);
  },
});

const dropdownMenuElementListeners = {
  mouseenter() {
    if (isMobileView.value || properties.trigger !== "hover") return;

    closeTimeout.stop();
    toggle(true);
  },
  mouseleave() {
    if (isMobileView.value || properties.trigger !== "hover") return;

    closeTimeout.start();
  },
};
</script>
